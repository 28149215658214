#cazafugas {
  & iframe {
    height: 30vw;
  }
  & header {
    margin-top: 4rem;
    margin-bottom: 4rem;
    & h2 {
      text-align: right;
      text-transform: uppercase;
      margin-top: 4rem;
    }
  }
  & .franchise-info {
    & h2 {
      text-align: center;
      margin-top: 0;
    }
  }
  & .franchise-videos {
    overflow:hidden;
    & ul {
      & li {
        display: inline-block;
        width: 4rem;
      }
      & img {
        width: 100%;
      }
    }    
  }
  & .video-info {
    border-bottom: 3px solid #275781;
    border-left: 3px solid #275781;
    padding-left: 2rem;
    & h2 {
      text-align: right;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }
    & a {
      display: block;
      &.active {
        border-radius: 100%;
        border: 3px solid #275781;
        padding: 2px;
      }
    }
  }
}