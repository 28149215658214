#who-we-are {
  margin-top: -4rem;
  & h1 {
    border-bottom: 3px solid #275781;
    display: inline-block;
    padding-right: 3rem;
    padding-bottom: 0.3rem;
  }
  & .business-info {
    padding-top: 4rem;
  }
}

.slider .indicators .indicator-item.active {
  background-color: #275781;
}