#contact {
  margin-top: 5rem;
  & .back-blue {
    background-color: #275781;
    padding: 2rem;
  }
  & h1 {
    color: #fff;
  }
  & h2 {
    border-bottom: 4px solid #275781;
    margin-bottom: 0;
    padding-bottom: 0.4rem;
    &:before {
      margin-right:2.2rem;
    }
  }
  & .text-info {
    & ul {
      margin-top: 0;
      padding-top: 0.75rem;
      border-right: 4px solid #275781;
      float: left;
      padding-right: 1rem;
      width: 260px;
      text-align: right;
      font-size: 1.2rem;
      & li {
        padding: 0.5rem;
      }
    }
    & .row:nth-child(2) {
      & li {
        display: inline-block;
        & a {
          font-size: 2.4rem;
          color: #000;
          border: 2px solid #000;
          border-radius: 10rem;
          height: 3.6rem;
          width: 3.6rem;
          display: block;
        }
      }
    }
  }
}

#map-canvas {
  height: 400px;
  border-bottom: 1rem solid #275781;
}


input:not([type]):focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search]:focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #275781;
  box-shadow: 0 1px 0 0 #275781;
}

input:not([type]):focus:not([readonly]) + label, input[type=text]:focus:not([readonly]) + label, input[type=password]:focus:not([readonly]) + label, input[type=email]:focus:not([readonly]) + label, input[type=url]:focus:not([readonly]) + label, input[type=time]:focus:not([readonly]) + label, input[type=date]:focus:not([readonly]) + label, input[type=datetime]:focus:not([readonly]) + label, input[type=datetime-local]:focus:not([readonly]) + label, input[type=tel]:focus:not([readonly]) + label, input[type=number]:focus:not([readonly]) + label, input[type=search]:focus:not([readonly]) + label, textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #275781;
}

.input-field label.active {
  font-size: 0.8rem;
  -webkit-transform: translateY(-160%);
  transform: translateY(-160%);
}

input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea {
  background-color: #d0d0d0; 
  border: 1px solid #9e9e9e;
}

.input-field {
  & label {
    margin-left: 1rem;
    color: #000;
  }
}

textarea.materialize-textarea {
  min-height: 200px;
}

.btn-large {
  background-color: #275781;
  border-radius: 0;
}
.btn:hover, .btn-large:hover {
  background-color: #b0b0b0;
}

/* CONTACT */
#msg{
  height: 64px;
  color: #fff;
}
#msg-all{
  display: none;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 20px;
  color: red;
}
.backGload{
  background: url('../media/loading.gif') no-repeat center;
  margin: 2rem 0;
}
.contact-error {
  -webkit-box-shadow: inset 0 0 2px red !important;
  -moz-box-shadow: inset 0 0 2px red !important;
  box-shadow: inset 0 0 2px red !important;
}