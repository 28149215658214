.parallax-container {
  height: 300px;
}

#services {
  margin-top: 4rem;
  & h1 {
    color: #fff;
    margin-top: 130px;
  }
  & .service-list {
    text-align: center;
    font-size: 4rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
  }
  & li {
    display: inline-block;
  }
  & span {
    font-weight: 700;
  }
}

.integral-maintenance-service {

}

.info-dividor {
  height: 100px;
  border:4px solid #275781;
  border-bottom: 0;
  margin-top: 3rem;
  &:before {
    content:"";
    width: 4px;
    background-color: #275781;
    display:block;
    margin: 0 auto;
    height: 2rem;
    margin-top: -2rem;
  }
}

.integral-maintenance-service, .air-conditioners-service {
  /*min-height: 600px;*/
  position: relative;
  text-align: center;
}

.services-items-list {
  text-align: left;
  margin-bottom: 6rem;
  & li {
    &:before {
      content: "• ";
      color: #275781;
      font-size: 2rem;
      line-height: 1rem;
      padding-top: 0.4rem;
    }
  }
}