.site-header {
  text-transform: uppercase;
  & svg {
    /*width: 50%;*/
    margin-top: 1rem;
  }
  & h1 {
    text-align: right;
    font-size: 1.5rem;
    margin-top: 3rem;
  }
  & h1, & a {
    font-weight: 700;
    font-family: 'Oswald', sans-serif;    
  }
  & a {
    font-size: 1.4rem;
  }
  & nav {
    background-color: rgba(39, 87, 129, 0.7);
    text-align: center;
  }
  & .center {
    display: inline-block;
  }
}


.escort {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  margin-top: 0;
}