#air-conditioners {
  margin-top: 4rem;
  & > .air-wrapper:nth-child(1) {
    background: linear-gradient(90deg, #275781 50%, #d0d0d0 0);
    & .valign-wrapper {
      height: 260px;
    }
    & .row {
      margin-bottom: 0;
    }
    & h1 {
      color: #fff;
    }
    & p {
      font-size: 1.6rem;
      text-align: right;
    }
  }
  & > .air-wrapper:nth-child(2) {
    background: linear-gradient(90deg, #fff 50%, #275781 0);
    & .row > .col:first-child {
      padding-right:0;
    }
    & .valign-wrapper {
      height: 184px;
    }
    & p {
      font-size: 1.6rem;
      text-align: right;
      color: #fff;
      width: 100%;
      & b {
        /*font-weight: 700;*/
        /*font-size: 2rem;*/
      }
    }
    & .carrier-brand {
      background-color: #d0d0d0;
    }
  }
}