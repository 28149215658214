html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: #b3d4fc;
  color: #fff;
  text-shadow: none;
}

.fonts-loaded body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

@viewport {
  width: device-width;
}

.container {
  width: 85%;
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 2.6rem;
  text-transform: uppercase;
}

h2 {
  font-size: 1.6rem;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 500;
}