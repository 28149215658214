.site-footer {
  padding: 1rem;
  background-color: #275781;
  color: #fff;
  & .brand-logo {
    margin-top: 1.7rem;
    & svg path {
      fill: #fff;
    }
  }
  & .footer-nav {
    margin: 0;
    padding-left: 2rem;
    list-style-type: square;
    & li {
      height: 1rem;
    }
  }
  & a {
    color: #fff;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  & .business-info {
    text-align: right;
    margin-top: 1.7rem;
    & span, & a, & small {
      display: block;
      font-size: 0.8rem;
      line-height: 1REM;
    }
  }
  & .footer-wrapper {
    margin-bottom: 0;
  }
  & .last-info {
    margin-top: 0.7rem;
  }
  & small {
    font-size: 0.7rem !important;
    margin-top: 0.7rem;
  }
}