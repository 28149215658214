#clients {
  margin-top: 4rem;
  & h1 {
    color: #fff;
    margin-top: 130px;
  }
  & .clients-wrapper {
    margin-top: 6rem;
    & li {
      width: 14.285%;
      /*display: inline-block;*/
      float: left;
      padding: 0;
      margin: 0;
    }
    & li:nth-child(2n) {
      background-color: #d0d0d0;
    }
    & img {
      width: 100%;
      filter: grayscale(100%);
    }
    & img:hover {
     filter: grayscale(0); 
    }
  }
}


@media only screen and (max-device-width : 970px) {
  #clients .clients-wrapper li {
    width: 20%;
  }
}

@media only screen and (max-device-width : 600px) {
  #clients .clients-wrapper li {
    width: 33.333%;
  }
}